<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm12 md4>
        <v-card class="elevation-12 overall-container">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-form
                  class="login-form-container py-15 px-10"
                  ref="form"
                  lazy-validation
                >
                  <label class="login-label"> Email </label>
                  <v-text-field
                    class="pt-0 mb-5"
                    single-line
                    :rules="$rules('Email', 'required|email')"
                    v-model="form.email"
                  />
                  <label class="login-label"> Password </label>
                  <v-text-field
                    class="pt-0 mb-0"
                    single-line
                    :rules="$rules('Password', 'required')"
                    v-model="form.password"
                    :append-icon="isPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="isPassword ? 'text' : 'password'"
                    @click:append="isPassword = !isPassword"
                    @keyup.enter.native="doLogin"
                  />
                  <div
                    @click="() => {}"
                    class="text-body-2 grey--text text--darken-4 text-right pointer"
                  >
                    Forgot Password?
                  </div>
                  <div class="d-flex justify-center mt-15">
                    <v-btn
                      depressed
                      color="primary"
                      dark
                      class="text-capitalize px-15 font-weight-bold login-btn"
                      large
                      @click="doLogin"
                      :loading="loading"
                    >
                      Sign-in
                    </v-btn>
                  </div>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "Login",
  data: () => ({
    form: {
      email: "",
      password: ""
    },
    isPassword: false,
    loading: false
  }),
  methods: {
    forgotPassword: function () {
      this.$router.replace("/forgot");
    },
    doLogin: async function (e) {
      e.preventDefault();
      if (this.$refs.form.validate()) {
        this.loading = true;
        try {
          let response = await this.$http.post(
            `${process.env.VUE_APP_API_URL}/auth/login`,
            {
              email: this.form.email,
              password: this.form.password
              // password: this.$helper.encryptPass(this.form.password)
            }
          );
          let {
            data: { success, data, message }
          } = response;
          if (success) {
            localStorage.setItem("_token", data.token);
            localStorage.setItem("_t", new Date().getTime());
            this.$router.replace("/");
          } else {
            this.$dialog.notify.error(message, {
              position: "top-right",
              timeout: 3000
            });
          }
        } catch (err) {
          // console.log(err);
        } finally {
          this.loading = false;
        }
      }

      return false;
    }
  }
};
</script>
<style scoped>
.login-label {
  /* color: #E040FB; */
  font-weight: 700;
}
.login-btn {
  border-radius: 17px;
}
.overall-container {
  border-radius: 44px;
}
.login-form-container {
  /* background-color: #f8e8ed; */
  border-radius: 44px;
}
</style>
